<template>
  <div class="mx-5 mt-4">
    <ciam-tabs :tabs="tabs()" @tab-change="onTabChange" :current="activeTab"></ciam-tabs>

    <div v-if="activeTab === 'keycloak'">
      <ciam-deployment-logs
        ref="deploymentLogs"
        :key="'keycloak-' + deployment.id"
        :deployment="deployment"
        source="keycloak"
      >
      <template v-slot:loading>
        <ciam-card-content-line type="full-width">
          <template v-slot:content>
            <ciam-loader class="py-4"></ciam-loader>
          </template>
        </ciam-card-content-line>
      </template>
      </ciam-deployment-logs>
    </div>

    <div v-else-if="activeTab === 'load-balancer'">
      <ciam-deployment-logs
        ref="deploymentLogs"
        :key="'load-balancer-' + deployment.id"
        :deployment="deployment"
        source="load-balancer"
      >
      <template v-slot:loading>
        <ciam-card-content-line type="full-width">
          <template v-slot:content>
            <ciam-loader class="py-4"></ciam-loader>
          </template>
        </ciam-card-content-line>
      </template>
      </ciam-deployment-logs>
    </div>
  </div>
</template>

<script>
import CiamTabs from '@/components/CiamTabs';
import CiamCardContentLine from '@/components/CiamCardContentLine';
import CiamDeploymentLogs from '@/pages/deployments/logs/CiamDeploymentLogs';

export default {
  name: 'DeploymentLogs',
  props: {
    deployment: {
      type: Object,
      required: true,
    },
  },
  components: {
    CiamTabs,
    CiamCardContentLine,
    CiamDeploymentLogs,
  },
  methods: {
    tabs() {
      return [
        { id: 'keycloak', name: 'Keycloak logs' },
        { id: 'load-balancer', name: 'Load balancer logs' },
      ];
    },

    onTabChange(selectedTab) {
      if (selectedTab && selectedTab.id) {
        this.updateQuery({ source: selectedTab.id });
      }
    },

    updateQuery(params) {
      this.$router.push({
        query: { ...this.$route.query, ...params },
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error('Router error:', err);
        }
      });
    },
  },
  computed: {
    activeTab() {
      const source = this.$route.query.source;
      return this.tabs().find(tab => tab.id === source)?.id || 'keycloak'; // default to 'keycloak'
    },
  },
  watch: {
    '$route.query.source'(newSource) {
      if (!this.tabs().some(tab => tab.id === newSource)) {
        this.updateQuery({ source: 'keycloak' }); // redirect to source=keycloak if invalid or empty
      }
    },
  },
  mounted() {
    const defaultTab = 'keycloak'; // default to keycloak tab
    const source = this.$route.query.source;

    if (!this.tabs().some(tab => tab.id === source)) {
      this.updateQuery({ source: defaultTab });
    }
  },
};
</script>